import React, { useEffect, useState } from "react";
import { useBalance } from "wagmi";

export interface IguBalanceProps {
  address: `0x${string}`;
  tokenAddress: `0x${string}`;
  networkId: string,
  style?: React.CSSProperties | undefined;
}

const IguBalance: React.FC<IguBalanceProps> = ({
  address,
  tokenAddress,
  networkId,
  style,
  ...props
}) => {
  const [balance, setBalance] = useState<{
    formatted?: string;
    balance?: unknown;
  }>({});

  const { data: rawBalance } = useBalance({
    address,
    token: tokenAddress,
    chainId: parseInt(networkId)
  });

  console.log(rawBalance);

  useEffect(() => {
    if (rawBalance)
      setBalance({
        formatted: rawBalance.value.gt(0)
          ? parseFloat(rawBalance.formatted).toFixed(4)
          : "0.0",
        balance: rawBalance.value,
      });
  }, [address, tokenAddress, rawBalance]);

  if (!balance?.formatted) return null;

  return (
    <span className="token token-with-ticker">
      <img src="/images/tokens/igu.svg?v=3" alt="IGU Token" />
      <span className="token-name">IGU</span>
      <b className="separator"></b>
      <b className="token-value"> {balance.formatted} </b>
    </span>
  );
};

export default IguBalance;
