import React from "react";

import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import config from "../../config";
import {
  faGear,
  faSignOutAlt,
  faPager,
  faCode,
  faCommenting,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useMoralis } from "react-moralis";
import Router, { useRouter } from "next/router";
import { useUser } from "../../hooks/useUser";
import Tippy from "@tippyjs/react";
import { useTranslations } from "../../hooks/useTranslations";
import { useIntercom } from "react-use-intercom";
import type { PostSaleAllocation } from "../../pages/api/sale/allocation";

interface Props {
  allocation?: PostSaleAllocation;
  showDropdown: boolean;
  onDropdownShowChange: (visible: boolean) => void;
}

export default function UserMenu({
  showDropdown,
  onDropdownShowChange,
}: Props) {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const user = useUser();
  const { boot, show } = useIntercom();

  function bootIntercom() {
    const userId = user?.id;
    const {
      ethAddress,
      createdAt,
      updatedAt,
      directReferrer,
      indirectReferrer,
      thirdReferrer,
      canRefer,
      maximumAllocationUsd,
      referDirectPercentage,
      referIndirectPercentage,
      referThirdPercentage,
      saleAllowOverwrite,
      customPriceUnder10k,
      customPriceAbove10k,
      username,
    } = user || {};
    const customAttributes = {
      ethAddress,
      createdAt,
      updatedAt,
      directReferrer,
      indirectReferrer,
      thirdReferrer,
      canRefer,
      maximumAllocationUsd,
      referDirectPercentage,
      referIndirectPercentage,
      referThirdPercentage,
      saleAllowOverwrite,
      customPriceUnder10k,
      customPriceAbove10k,
      username,
    };
    boot({ userId, customAttributes });
    show();
  }

  const setLocale = (locale: string): void => {
    router.push({ pathname, query }, asPath, { locale });
  };

  const { header } = useTranslations();
  const {
    whitepaper,
    website,
    support,
    logout_button,
    username,
    username_tooltip,
    allocation_whitelist_title,
    allocation_nft_title,
    allocation_title,
    allocation_tooltip,
    wallet,
  } = header;

  return (
    <Dropdown
      as={NavItem}
      className="ms-auto"
      style={{paddingLeft: 5}}
      align="end"
      show={showDropdown}
      onToggle={(val) => onDropdownShowChange(val)}
    >
      <Dropdown.Toggle as={NavLink} className="pe-0" id="userInfo">
        <FontAwesomeIcon icon={faGear} size="lg" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-animated"
        aria-labelledby="userInfo"
        data-bs-popper="none"
      >

        {/*<Dropdown.Item href={config.socials.mainUrl} target="_blank">
          <FontAwesomeIcon icon={faPager} fixedWidth={true} /> {website}
        </Dropdown.Item>
        <Dropdown.Item href={config.socials.whitepaperUrl} target="_blank">
          <FontAwesomeIcon icon={faCode} fixedWidth={true} /> {whitepaper}
        </Dropdown.Item>*/}
        <Dropdown.Item onClick={() => bootIntercom()}>
          <FontAwesomeIcon icon={faCommenting} fixedWidth={true} /> {support}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => setLocale("en")}>
          <span>English</span>
          <span className="lang-code">EN</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLocale("pl")}>
          <span>Polski</span>
          <span className="lang-code">PL</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLocale("uk")}>
          <span>Українська</span>
          <span className="lang-code">UA</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLocale("zh")}>
          <span>简体中文</span>
          <span className="lang-code">zh_CN</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLocale("ru")}>
          <span>Русский</span>
          <span className="lang-code">RU</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
