import { useRouter } from "next/router";
import Link from "next/link";
import React, { Children, PropsWithChildren } from "react";

interface Props {
  activeClassName: string;
  href: string;
}

const ActiveLink = ({
  children,
  activeClassName,
  ...props
}: PropsWithChildren<Props>) => {
  const { asPath } = useRouter();
  const child = Children.only(children) as any;
  const childClassName = child.props.className || "";

  const className =
    asPath === props.href
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
};

export default ActiveLink;
