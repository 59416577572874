import React, { useState } from "react";

import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faAlignRight,
} from "@fortawesome/pro-light-svg-icons";
import Link from "next/link";
import UserMenu from "./UserMenu";
import ConnectButtonExp from "../web3own/ConnectButton";
import { useTranslations } from "../../hooks/useTranslations";
import config from "../../config";
import Tippy from "@tippyjs/react";
import IguBalance from "../web3own/IguBalance";
import { useAccount } from "wagmi";
import { isAddress } from "ethers/lib/utils.js";
import Image from 'next/image';

const { iguContract, chainId } = config;

interface Props {
  setSidebarShrink: (value: boolean) => void;
  sidebarShrink: boolean;
}

export default function Header({ setSidebarShrink, sidebarShrink }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { header } = useTranslations();
  const {
    wallet_balance_bnb_tooltip,
    tokens_balance_tooltip,
    connect_wallet_tooltip,
  } = header;

  const { address } = useAccount();

  return (
    <header className="header">
      <Navbar
        bg="white"
        expand="lg"
        variant={undefined}
        className="px-4 py-3 shadow"
      >
        <div className="navbar-sidebar">
          <a
            className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead"
            href="#"
            onClick={() => setSidebarShrink(!sidebarShrink)}
          >
            {!sidebarShrink ? (
              <FontAwesomeIcon icon={faAlignLeft} className="icon-hamburger" />
            ) : (
              <FontAwesomeIcon icon={faAlignRight} className="icon-hamburger" />
            )}
          </a>
        </div>

        <div>
        <Link href="/" passHref>
          <Navbar.Brand className="fw-bold text-uppercase text-base navbar-brand">
            <Image
              width={195}
              height={30}
              src="/images/logo-long.webp"
              alt="Logo IguVerse"
            />
          </Navbar.Brand>
        </Link>
        </div>

        <div className="d-flex align-items-center mb-0 user-menu">
          {address && (
            <Tippy
              content={tokens_balance_tooltip}
            >
              {isAddress(iguContract) ? 
              <div className="user-balance position-relative d-md-none d-xl-flex">
                <IguBalance address={address} networkId={chainId} tokenAddress={iguContract}></IguBalance>
              </div>
              : 
              <div></div>}
            </Tippy>
          )}
          <ConnectButtonExp/>
          <UserMenu
            showDropdown={dropdownOpen}
            onDropdownShowChange={(val) => setDropdownOpen(val)}
          />
        </div>
      </Navbar>
    </header>
  );
}