import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslations } from "../hooks/useTranslations";

export default function AppAcceptCookie () {
  const { cookie_translations } = useTranslations();

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={cookie_translations.button}
        cookieName="acceptCookie"
        style={{ background: "#40bf95" }}
        buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
        expires={30}
      >
        <div className="cookie-header">
          <h4 className="cookie-header-title">{cookie_translations.title}</h4>
          <p className="cookie-header-description">{cookie_translations.description}</p>
        </div>
      </CookieConsent>
    </>
  );
};
