import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import type { UserCustomFields } from "../models/user-custom-fields";

const modifyAttributes = (attr: UserCustomFields) => ({
  ...attr,
  username: attr?.username?.slice(0, 10) || "",
});

export const useUser = (): UserCustomFields | null => {
  const ctx = useContext(UserContext);

  const maybeAttributes = ctx?.user?.attributes as UserCustomFields | null;

  return maybeAttributes && Object.keys(maybeAttributes).length > 0
    ? modifyAttributes(maybeAttributes)
    : null;
};
