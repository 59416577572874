import enTranslations from "./en.json";
import plTranslations from "./pl.json";
import ukTranslations from "./uk.json";
import zhTranslations from "./zh.json";
import ruTranslations from "./ru.json";

export type Translations = typeof enTranslations;

export const getTranslations = (locale: string): Translations => {
  switch (locale) {
    case "pl":
      return plTranslations;
    case "uk":
      return ukTranslations;
    case "zh":
      return zhTranslations;
    case "ru":
      return ruTranslations;
    default:
      return enTranslations;
  }
};
