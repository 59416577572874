import React from "react";
import {
  WrapperStyled,
} from "./styles";
import { useAccount } from 'wagmi'
import { ConnectButton } from "@rainbow-me/rainbowkit";

const ConnectButtonExp: React.FC = ({
  ...props
}) => {
  const { address } = useAccount();

  if (!address) {
    return (
      <WrapperStyled>
         <ConnectButton  />
      </WrapperStyled>
    );
  }

  return (
    <WrapperStyled {...props}>
      <ConnectButton accountStatus={{
    smallScreen: 'avatar',
    largeScreen: 'full',
  }}/>
    </WrapperStyled>
  );
};

export default ConnectButtonExp;
