import type { FallbackProps } from "react-error-boundary";

export function AppErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <section className="js-error">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto">
            <div className="card">
              <div className="card-body">
                <h5 className="text-danger fw-bold title">Client side error</h5>

                <p className="mt-5">
                  Something went wrong in your browser. Try refresh the page
                </p>

                <div className="mt-5">
                  <pre>{error.message}</pre>
                </div>

                <div className="mt-5">
                  <a className="btn btn-buy" href="">
                    Refresh
                  </a>
                </div>
              </div>
              <div className="card-footer text-reset">
                Contact us if you can not enter the website for extended period
                of time
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
