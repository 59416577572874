import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getTranslations, Translations } from "../i18n/translations";
import enTranslations from "../i18n/en.json";
import { useLocale } from "./useLocale";

export function format(oStr: string, ...args: string[]) {
  var num = arguments.length;
  for (var i = 1; i < num; i++) {
    var pattern = "\\{" + (i - 1) + "\\}";
    var re = new RegExp(pattern, "g");
    oStr = oStr.replace(re, arguments[i]);
  }
  return oStr;
}

export const useTranslations = () => {
  const locale = useLocale();
  const [translations, setTranslations] =
    useState<Translations>(enTranslations);

  useEffect(() => {
    setTranslations(getTranslations(locale));
  }, [locale]);

  return translations;
};
