import "@fortawesome/fontawesome-svg-core/styles.css";
import "../styles/style.scss";

import { useEffect } from "react";
import { AppProps } from "next/app";
import { SSRProvider } from "react-bootstrap";
import Layout from "../components/Layout";
import AppAcceptCookie from "../components/AppAcceptCookie";
import { NotificationProvider } from "web3uikit";
import { IntercomProvider } from "react-use-intercom";
import config from "../config";
import { ErrorBoundary } from "react-error-boundary";
import { AppErrorFallback } from "../components/AppErrorFallback";
import "tippy.js/dist/tippy.css";
import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets, getDefaultWallets, lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import {
  metaMaskWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

const { intercomInitializeDelayMs, isProduction } = config;

const chain = isProduction ? bsc : bscTestnet;

const { chains, provider } = configureChains(
  [chain],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ chains }),
      walletConnectWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    import("bootstrap");
  }, []);
  return (
    <SSRProvider>
      <ErrorBoundary fallbackRender={AppErrorFallback}>
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains} modalSize="compact" initialChain={chain} theme={lightTheme({
            accentColor: '#0fa',
            accentColorForeground: '#2e7daf',
            borderRadius: 'medium',
          })}>
            <IntercomProvider
              appId={config.intercomAppId}
              initializeDelay={intercomInitializeDelayMs}
            >
              <NotificationProvider>
                <Layout {...pageProps}>
                  <Component {...pageProps} />
                </Layout>
              </NotificationProvider>
              <AppAcceptCookie></AppAcceptCookie>
            </IntercomProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </ErrorBoundary>
    </SSRProvider>
  );
}

export default MyApp;

