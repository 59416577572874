import React from "react";
import SocialsList from "./SocialsList";
import {useTranslations} from "../hooks/useTranslations";

export default function Footer() {
  const { footer } = useTranslations();

  return (
    <footer>
        <div className="copyright">
          <p className="mb-0">IguVerse &copy; 2022</p>
        </div>
        <SocialsList />
        <div className="version">
          <p className="mb-0">{footer.version} 1.0.0</p>
        </div>
    </footer>
  );
}
