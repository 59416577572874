import React, { useState, useEffect, PropsWithChildren } from "react";
import {useRouter} from "next/router";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import config from "../config";
import { useTranslations } from "../hooks/useTranslations";
import Script from "next/script";


const { googleTagManagerId } = config;
interface Props {
  className: string;
  title: string;
  hideSidebar: boolean;
  pageHolderClass: string;
  hideFooter: boolean;
  hideHeader: boolean;
}

const Layout = (pageProps: PropsWithChildren<Props>) => {
  const [sidebarShrink, setSidebarShrink] = useState(false);
  const { meta } = useTranslations();

  const page = {
    title: meta.title,
    description: meta.description,
    keywords: meta.keywords,
    appUrl: "https://app.iguverse.com",
  };

  // Add facebook pixel
  const router = useRouter()

  useEffect(() => {
    import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(config.facebookPixelId) // facebookPixelId
          ReactPixel.pageView()

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView()
          })
        })
  }, [router.events])

  return (
    <div className={pageProps.className}>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/images/favicons/apple-icon-60x60.png?v=3"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/images/favicons/apple-icon-72x72.png?v=3"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/images/favicons/apple-icon-72x72.png?v=3'"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/images/favicons/apple-icon-76x76.png?v=3'"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/images/favicons/apple-icon-114x114.png?v=3"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/images/favicons/apple-icon-120x120.png?v=3'"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/images/favicons/apple-icon-144x144.png?v=3'"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/favicons/apple-icon-152x152.png?v=3'"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/favicons/apple-icon-180x180.png?v=3'"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/images/favicons/android-icon-192x192.png?v=3"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicons/favicon-32x32.png?v=3"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/images/favicons/favicon-96x96.png?v=3"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicons/favicon-16x16.png?v=3"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png?v=3" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="description" content={page.title} />
        <meta name="keywords" content={page.keywords} />
        <meta name="author" content="IguVerse" />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta property="og:url" content={page.description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <meta property="og:image" content="https://app.iguverse.com/images/og/1200x630.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta property="og:image" content="https://app.iguverse.com/images/og/1200x1200.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={config.socials.twitterUsername} />
        <meta name="twitter:creator" content={config.socials.twitterUsername} />
        <meta name="twitter:image" content="https://app.iguverse.com/images/og/1200x630.png" />
      </Head>
      <NextNprogress color="#4E66F8" options={{ showSpinner: false }} />
      {!pageProps.hideHeader && (
        <Header
          setSidebarShrink={setSidebarShrink}
          sidebarShrink={sidebarShrink}
        />
      )}

      <div className="d-flex align-items-stretch">
        {!pageProps.hideSidebar && <Sidebar sidebarShrink={sidebarShrink} />}
        <div
          className={`page-holder ${
            pageProps.pageHolderClass
              ? pageProps.pageHolderClass
              : "bg-gray-100"
          }`}
        >
          <div className="page-holder-wrapper">
            {pageProps.children}
          </div>
          {!pageProps.hideFooter && <Footer />}
        </div>
      </div>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleTagManagerId}');
        `}
      </Script>
    </div>
  );
};

export default Layout;
