import type { NftRank } from "./services/nft";

const isProduction =
  process.env.VERCEL_ENV === "production" ||
  (typeof location !== "undefined" && location.host === "app.iguverse.com");

const config = {
  isProduction,
  chainId: isProduction ? "56" : "97",
  isClaimEnabled: true,
  cacheReferralsDataSeconds: 1 * 60,
  saleCacheTransactionHistoryInterval: 15 * 1000, // frontend interval pooling
  busdContract: isProduction
    ? "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    : "0xd8E9437e6eE53C250aef6c009040402B3f348d6C",
  approvalRequiredConfirmations: 1, // There is no point of having more for approval.
  crowdsaleContract: isProduction
    ? "0x80c0b0e2661404D5c48d00B5469538B418E6ed51"
    : "0x91d5AA9e0771A20C2E1c214Ec0DD37caec22a725",
  vestingContract: isProduction
    ? "0x7719ee8B419D33B10654bf61A2D7ea14B7952A3a"
    : "0x7eA1E6bf09c9C0A29129B0b8b68c2Ff22b0b3aaa",
  iguContract: isProduction? '0x201bC9F242f74C47Bbd898a5DC99cDCD81A21943' : '0xb34232a1e0a0a24ef502c59c91061ec475b33471',
  rpcUrl: isProduction
    ? "https://bscrpc.com"
    : "https://data-seed-prebsc-1-s1.binance.org:8545/",
  depositRequiredConfirmations: 3, // Bogdan's suggestion
  chainBalance: (isProduction ? "bsc" : "bsc testnet") as "bsc" | "bsc testnet",
  chainNetworkSwitch: isProduction ? 0x38 : 0x61, // bsc mainnet - 0x38 (56), bsc testnet - 0x61 (97)
  saleStatsCacheTtlSeconds: 10,
  saleStatsPoolingIntervalMs: 20 * 1000,
  nftCacheTtlSeconds: 60 * 60,
  preloaderTimeout: 20 * 1000,
  intercomAppId: "es9h4h4r",
  facebookPixelId: "2944615852498644",
  intercomInitializeDelayMs: 5000,
  googleTagManagerId: "G-4T7W2ZMYFB",
  dataPoolingIntervalMs: 30 * 1000,
  referralMarkAccountsNotOlderThanMiunutes: 30,
  nfts: {
    binance: {
      chain: "bsc" as "bsc",
      token_address: "0x1ddb2c0897daf18632662e71fdd2dbdc0eb3a9ec",
    },
    opensea: {
      chain: "polygon" as "polygon",
      token_address: "0x2953399124F0cBB46d2CbACD8A89cF0599974963",
    },
  },
  socials: {
    mainUrl: "https://iguverse.com",
    whitepaperUrl: "https://whitepaper.iguverse.com",
    whitepaperIguanasRank0Url:
      "https://whitepaper.iguverse.com/private-sale/iguanas-rank-0",
    faqUrl: "https://faq.iguverse.com/private-sale",
    discordUrl: "https://discord.gg/iguverse",
    telegramUrl: "https://t.me/iguverse",
    twitterUrl: "https://twitter.com/iguverse",
    twitterUsername: "@iguverse",
    cmcUrl: "https://coinmarketcap.com/currencies/iguverse/",
    linktreeUrl: "https://linktr.ee/iguverse",
  },
  sale: {
    startUTC: Date.UTC(2022, 5, 25, 17), // Months here counted from 0
    // startUTC: Date.UTC(2022, 5, 22, 21, 18), // Months here counted from 0 (-2h my time)
    endUTC: Date.UTC(2022, 6, 17, 17), // Months here counted from 0
    priceIncreaseUTC: Date.UTC(2022, 6, 12, 17),
    tokensHardcap: 72000000, // changing this will affect backend logic.
    tokensHardcapUsd: "539995179.00", // changing this will affect backend logic.
    maxSupply: 400000000, // this number is just for displaying purposes does not affect logic
    tokensFrom0Ranks: 0, // 400000 * 10 + 370370 * 10, // tokens reserved for Iguanas Ranks 0: 400000 * 10 + 370370 * 10
    defaultMaximumAllocationUSD: 50000,
    defaultMaximumAllocationUSDWithRank0NFT: 300000,
    usdHardcap: 1250000, // this number is just for displaying purposes does not affect logic
    rankUSDAmounts: {
      "-1": 0, // -1 to handle unhandled situations
      1: 1000,
      2: 800,
      3: 600,
      4: 500,
      5: 400,
      6: 300,
      7: 250,
      8: 200,
      9: 150,
      10: 100,
    } as { [key in NftRank]: number },
    nftPricePerToken: 0.03,
    above10kPricePerToken: 0.036,
    under10kPricePerToken: 0.048,
    publicRoundPricePerToken: 0.06, // this number is just for displaying purposes does not affect logic
    minimalAllocationUSDWithNft: 100,
    minimalAllocationUSDWithoutNft: 100,
  },
  mintNft: {
      maxFileUploadSize: 5 * 1024 * 1024
  },
  nftRank0Stage1: {
    pricePerToken: 0.025,
    amountUSD: 10000,
    tokenIds: [
      "100301399835",
      "100301399841",
      "100301399846",
      "100301399851",
      "100301399854",
      "100301399858",
      "100301399860",
      "100301399863",
      "100301399866",
      "100301399872",
    ],
  },
  nftRank0Stage2: {
    pricePerToken: 0.027,
    amountUSD: 10000,
    tokenIds: [
      "100301414465",
      "100301414466",
      "100301414467",
      "100301414471",
      "100301414521",
      "100301414525",
      "100301414528",
      "100301414530",
      "100301414531",
      "100301414534",
    ],
  },
  mocks: {
    nfts: [
      {
        name: "IGUANA BAD BOY 3rd Rank Bronze mock",
        description:
          "3rd Rank Bronze allocation in private sales of ($300) +80 hashrate Each NFT owner will get guaranteed allocation, depending on his/her rank, on the stage of IGUANA (IGU) token private sale. In addition, each nft has its own hash rate, depending on the rank, with which you can mine IGUANA (IGU) tokens. Website: iguanametaverse.com",
        image:
          "https://lh3.googleusercontent.com/Z8ij3LbC9GrHsq8TSicWt70oo_4DRdVi4YfhxEMxDRWRMw8z0XmONrgw6fRk20uy3wuUHrjMwcAW2eLm_g3E3sjPpl17MaZzOqGTvaY",
        rank: 3,
        token_id:
          "17841288640505514226644911213060697199572379551450939960581357825438460674049",
        amountUSD: 600,
      },
      {
        name: "HONORARY IGUANA RANK 0 BARU mock",
        description:
          "Honorary Iguana Rank 0 is a special limited kind of NFT, that will give the owner the right for airdrop of 400,000 IGU tokens with special price $0.025 per token during the Private Sale Round. There are only 20 Iguanas Rank 0 in the game. They are the most powerful characters in the IguVerse P2E game. The Iguana Baru character is based on real iguana that lives in Bali Reptile Park. Baru is 3 years old female green iguana from Colombia.",
        image:
          "https://public.nftstatic.com/static/nft/res/0f56fe57767e4435b9fad5fd58c9afde.jpeg",
        rank: 0,
        token_id: "100301399835",
        amountUSD: 10000,
      },
      {
        name: "HONORARY IGUANA RANK 0 Mock",
        description:
          "Honorary Iguana Rank 0 is a special limited kind of NFT, that will give the owner the right for airdrop of 400,000 IGU tokens with special price $0.025 per token during the Private Sale Round. There are only 20 Iguanas Rank 0 in the game. They are the most powerful characters in the IguVerse P2E game. The Iguana Baru character is based on real iguana that lives in Bali Reptile Park. Baru is 3 years old female green iguana from Mocklandia.",
        image:
          "https://public.nftstatic.com/static/nft/res/b73def0c44a0424585817453b7946454.jpeg",
        rank: 0,
        token_id: "100301414465",
        amountUSD: 10000,
      },
      {
        name: "HONORARY IGUANA RANK 0 SENA mock",
        description:
          "Honorary Iguana Rank 0 is a special limited kind of NFT, that will give the owner the right for airdrop of 400,000 IGU tokens with special price $0.025 per token during the Private Sale Round. There are only 20 Iguanas Rank 0 in the game. They are the most powerful characters in the IguVerse P2E game. The Iguana Sena character is based on real iguana that lives in Bali Reptile Park. Sena is 4 years old female green iguana from Colombia.",
        image:
          "https://public.nftstatic.com/static/nft/res/467f08c6418140f89b5da0c69bed532b.jpeg",
        rank: 0,
        token_id: "100301399872",
        amountUSD: 10000,
      },
    ],
  },
};

export default config;
