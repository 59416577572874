import { useRouter } from "next/router";

export function useLocale() {
  const { locale } = useRouter();
  if (locale && ["en", "uk", "ru", "pl", "zh"].includes(locale)) {
    return locale;
  } else {
    return "en";
  }
}
