import React from "react"
import config from "../config";
import SvgIcon from "./Icons/SvgIcon";

export default function SocialsList() {
    return (
        <ul className="social-list">
            <li>
                <a
                    className="social-list-item social-discord"
                    href={config.socials.discordUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon type={"discord"} />
                </a>
            </li>
            <li>
                <a
                    className="social-list-item social-telegram"
                    href={config.socials.telegramUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon type={"telegram"} />
                </a>
            </li>
            <li>
                <a
                    className="social-list-item social-twitter"
                    href={config.socials.twitterUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon type={"twitter"} />
                </a>
            </li>
            <li>
                <a
                    className="social-list-item social-cmc"
                    href={config.socials.cmcUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon type={"cmc"} />
                </a>
            </li>
            <li>
                <a
                    className="social-list-item social-linktree"
                    href={config.socials.linktreeUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon type={"linktree"} />
                </a>
            </li>
        </ul>
    )
}




