import React, { useContext } from "react";
import config from "../config";
import ActiveLink from "./ActiveLink";
import { useTranslations } from "../hooks/useTranslations";
import { useIntercom } from "react-use-intercom";
import {
  faList,
  faPager,
  faCode,
  faQuestion,
  faCommenting,
  faPiggyBank,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccount } from "wagmi";

interface Props {
  sidebarShrink: boolean;
}

export default function Sidebar({ sidebarShrink }: Props) {
  const {address} = useAccount();
  const { boot, show } = useIntercom();

  function bootIntercom() {
    const userId = address;
    const customAttributes = {
      ethAddress: address,
    };
    boot({ userId, customAttributes });
    show();
  };

  const { layout } = useTranslations();
  const {
    main,
    docs,
    whitelist,
    website,
    whitepaper,
    faq,
    support,
    claim
  } = layout;

  const data = [
    {
      name: main,
      items: [
        {
          name: claim,
          link: "/claim",
          icon: faPiggyBank,
          blank: false,
          new: false
        },
      ],
    },
    {
      name: docs,
      items: [
        /*{
          name: whitelist,
          link: "/whitelist",
          icon: faList,
          blank: false,
          new: false
        },*/
        {
          name: website,
          link: config.socials.mainUrl,
          icon: faPager,
          blank: true,
          new: false
        },
        {
          name: whitepaper,
          link: config.socials.whitepaperUrl,
          icon: faCode,
          blank: true,
          new: false
        },
        {
          name: faq,
          link: config.socials.faqUrl,
          icon: faQuestion,
          blank: true,
        },
        {
          name: support,
          link: null,
          onClick: bootIntercom,
          icon: faCommenting,
          blank: true,
          new: false
        },
      ],
    },
  ];

  return (
    <div
      className={`sidebar py-3 ${sidebarShrink ? "shrink show" : ""}`}
      id="sidebar"
    >
      {data.map((block) => (
        <React.Fragment key={block.name}>
          <h6 className="sidebar-heading">{block.name}</h6>
          <ul className="list-unstyled">
            {block.items.map((item) => (
              <li key={item.name} className="sidebar-list-item">
                {item.link ? (
                  <ActiveLink href={item.link} activeClassName="active">
                    <a
                      className={`sidebar-link text-muted`}
                      target={item.blank ? "_blank" : "_self"}
                    >
                      <FontAwesomeIcon
                        icon={item.icon}
                        fixedWidth={true}
                        className="fa-thin"
                      />
                      <span className="sidebar-link-title">{item.name}</span>
                      {item.new && <span className="badge bg-danger text-uppercase">new</span>}
                    </a>
                  </ActiveLink>
                ) : (
                  <a
                    className={`sidebar-link text-muted`}
                    onClick={item.onClick}
                    href="#"
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      fixedWidth={true}
                      className="fa-thin"
                    />
                    <span className="sidebar-link-title">{item.name}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  );
}