import styled, { css } from "styled-components";

const resetCSS = css`
  border: none;
  box-sizing: border-box;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
`;

const resetButtonCSS = css`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
`;

export const color = {
  blueLight: "#BDFFE9",
  white: "#FFFFFF",
  paleBlue2: "#C1D8E7",
  blue: "#2E7DAF",
  grey: "#393C46",
  blueLight2: "#99FFDD",
  greyIcons: "#B0B5BF",
};

const text = css`
  -webkit-font-smoothing: antialiased;
  color: ${color.grey};
  fill: ${color.grey};
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
`;

const semiBold = css`
  ${text};
  font-weight: 600;
`;

export const fonts = {
  semiBold,
};

const flexBox = css`
  ${resetCSS};
  align-items: center;
  display: flex;
`;

export const WrapperStyled = styled.div`
  ${flexBox};
  height: 40px;
  margin: 0 16px;
  white-space: nowrap;
`;

export const AccountInfoStyled = styled.div`
  ${flexBox};
  background-color: ${color.blueLight};
  border-radius: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding: 3px;
  user-select: none;
  width: max-content;
`;

export const ConnectButtonStyled = styled.button`
  ${resetButtonCSS};
  ${fonts.semiBold}
  background-color: ${color.blueLight};
  border-radius: 1000px;
  height: auto;
  box-shadow: 0px 4px 0px #86DFC2;
  padding: 10px 20px;
  transition: all 0.1s ease-out;
  &:hover {
    background-color: ${color.blueLight2};
  }
`;

export const AddressStyled = styled.div`
  ${flexBox};
  background: ${color.white};
  border-radius: 12px;
  border: 1px solid transparent;
  cursor: pointer;
  height: 100%;
  line-height: 25px;
  padding: 2px 8px;
  transition: all 0.1s ease-out;
  &:hover {
    border-color: ${color.paleBlue2};
  }
`;

export const TextStyled = styled.span`
  ${fonts.semiBold}
  color: ${color.grey};
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
`;

export const BalanceBlockStyled = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
`;

export const BalanceStyled = styled.span`
  ${fonts.semiBold}
  white-space: nowrap;
  line-height: 25px;
`;

export default resetCSS;
