import React, { useEffect } from "react";
import { useMoralis } from "react-moralis";
import type Moralis from "moralis/types";

type UserCtx = { user: Moralis.User | null; refetch: () => void };

export const UserContext = React.createContext<UserCtx | null>(null);

const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { user, isAuthenticated, refetchUserData } = useMoralis();

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    if (isAuthenticated) refetchUserData();
  }, [isAuthenticated]);

  const refetch = () => {
    refetchUserData().catch((err) =>
      console.error("Could not refetchUserData - user context, error: ", err)
    );
  };

  return (
    <UserContext.Provider value={{ user, refetch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
